import React from 'react'
import {
  Accent,
  DoodleLayout,
  Headline1,
  Paragraph,
  Spacing,
  Button,
  useIsViewportMobile,
  Grid,
  GridArea,
} from '@boxine/tonies-ui'
import { useTranslation } from 'next-i18next'
import { FontPreload } from '../../components/organisms/Meta/components/FontPreload'
import { ToniesGlobalStyles } from '../../tonies-ui/styles/ToniesGlobalStyles'
import { LogoLink } from '../../components/organisms/MainNavigation/components/LogoLink'
import RitterDragon from './assets/RitterDragon.svg'
import { PreventCache } from '../../components/organisms/Meta/components/PreventCache'
import { useLocale } from '../../providers/locale'
import { shopConfig } from '../../config/shop'

export type ErrorLayoutType =
  | 'httpError'
  | 'paymentError'
  | 'default'
  | 'orderError'

interface ErrorLayoutProps {
  statusCode?: number
  message?: string
  ctaAction?: () => void
  hasHeader?: boolean
  errorLayoutType?: ErrorLayoutType
  dataTestId?: string
  cartId?: string
}

export const ErrorLayout = ({
  hasHeader,
  message,
  ctaAction,
  statusCode,
  errorLayoutType = 'httpError',
  dataTestId = 'error-page',
  cartId,
}: ErrorLayoutProps) => {
  const isMobile = useIsViewportMobile()
  const { t } = useTranslation()
  const lcCC = useLocale()

  const renderStatusText = () => {
    if (errorLayoutType === 'httpError' && statusCode) {
      if (statusCode === 404) {
        return t('errorPage:text:httpError404')
      } else if (statusCode >= 500 && statusCode < 600) {
        return t('errorPage:text:httpError5xx')
      }
    } else {
      return t(`errorPage:text:${errorLayoutType}`, {
        cartId: cartId || 'xxx-xxxx-xxxx',
      })
        .split('\n')
        .map((t: string, i: number) =>
          t ? (
            <>
              <span key={i}>{t}</span>
              <br />
              <br />
            </>
          ) : null
        )
    }
    return t('errorPage:text:default')
  }

  return (
    <>
      <PreventCache />
      <FontPreload />
      <ToniesGlobalStyles />
      <DoodleLayout
        doodle={<RitterDragon />}
        headerLogo={
          hasHeader && (
            <LogoLink
              logoAlt="tonies\u00ae"
              logoSrc={shopConfig.assets.toniesLogo}
              logoLink="/"
              isMobile={isMobile}
            />
          )
        }
      >
        <div data-testid={dataTestId}>
          <Spacing mb="spacing-s" mt="spacing-xl">
            <Headline1 data-testid={`${dataTestId}__headline`}>
              <Accent id="oh-boy" data-testid="error-code-500">
                {t(`errorPage:headline:${errorLayoutType}`)}
              </Accent>
            </Headline1>
          </Spacing>
          <Spacing mb="spacing-xs">
            <Headline1
              asHTMLTag="h2"
              data-testid={`${dataTestId}__subheadline`}
            >
              {t(
                `errorPage:subHeadline:${errorLayoutType}`,
                statusCode
                  ? {
                      code: statusCode,
                    }
                  : undefined
              )}
            </Headline1>
          </Spacing>
          <Spacing mb="spacing-m">
            <Paragraph data-testid={`${dataTestId}__text`}>
              {renderStatusText()}
              {message && (
                <span data-testid={`${dataTestId}__errorMessage`}>
                  {t('checkout:payment:errorContext')}: {message}{' '}
                </span>
              )}
            </Paragraph>
          </Spacing>
          <Spacing mb="spacing-xl" mr="spacing-m" applyToChildren={true}>
            <Grid columns={2} hasGapH>
              <GridArea colStart={0}>
                {ctaAction && (
                  <Button
                    data-testid={`${dataTestId}-tryagain-button`}
                    onClick={ctaAction}
                  >
                    {t('checkout:payment:retry')}
                  </Button>
                )}
              </GridArea>
              <GridArea colStart={ctaAction ? 1 : 0} contentAlignV="center">
                <Button
                  variant="secondary"
                  as="a"
                  href={`/${lcCC.toLowerCase()}/`}
                  data-testid={`${dataTestId}__backToHome`}
                >
                  {t('errorPage:backToHome')}
                </Button>
              </GridArea>
            </Grid>
          </Spacing>
        </div>
      </DoodleLayout>
    </>
  )
}
